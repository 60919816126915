import axios from "axios";

const FbPostAction =()=>{
    return (dispatch)=>{
        dispatch({
              type:"LOADING_FB_POST",
              isFetching:true  
        })
        const access_token="EAAIqLXQVD0cBAKQBFAw2vodpHsYGJGDVyRqPpW9m40zZBwpXT4vpJZAghFcfC9GahCSyRqwCuUbMBngZB7BeIHAG0ERz0l4ifJaCOs9bz1PPGSkQSNL0qZCJpp3r45LMvW7TM3BRBcrFQXXmAEr17tCMZBZAAKvM3glySAFZAq7VwZDZD"
        return axios.get(`https://graph.facebook.com/singeldingen?fields=app_id,posts.limit(10){id,message,created_time,link,event,full_picture}&access_token=${access_token}`)
        .then( (response) =>{
            const fbPost= response.data.posts
            dispatch({
                type:"FB_POST_SUCCESS",
                fbPost,
                isFetching:false  
            })
        })
        .catch( (error)=> {
            dispatch({
                type:"FB_POST_FAILD",
                error,
                isFetching:true
            })
        })
        
    }
}

export default FbPostAction