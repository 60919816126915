import React from 'react'
import Nav from './Nav';
import Title from './Title';

const Header = () => {
  return (
    <div>

          <Title />

          <Nav />
          
    </div>
  )
}

export default Header
