import React from 'react'

const Footer = () => {
  const logos = [4,5,6,7,8,10,1,2,3,9]
  const logo = logos.map((value,index)=>{
    return <img className="logo" src={`template/img/sp${value}.jpeg`} key={index} alt={`logo${index}`}/>
  })
  return (
    <footer className="footer text-faded text-center py-5">
        <div className="container">
        {logo}
        <p className="m-0 small">Copyright &copy; 2019 All rights reserved | This Website is made by
        <a href="http://www.codematrix.nl"> Code Matrix Zone</a></p><br/>
        </div>
  </footer>
  )
}

export default Footer
