import React from 'react'

const AboutPage = () => {
  return (
    <section className="page-section about-heading">
    <div className="container">
      <img className="img-fluid rounded about-heading-img mb-3 mb-lg-0" src="template/img/kiosk.png" alt=""/>
      <div className="about-heading-content">
        <div className="row">
          <div className="col-xl-9 col-lg-10 mx-auto">
            <div className="bg-faded rounded p-5">
              <h2 className="section-heading mb-4">
                {/* <span className="section-heading-upper">VOOR EN DOOR DE BUURT</span> */}
                <span className="section-heading-lower">“VOOR EN DOOR DE BUURT”</span>
              </h2>
              <p>Een Oudhollands spreekwoord zegt “ een goede buur is beter dan een verre vriend”. Het staat voor het idee dat een buur dichterbij is en vaker bij je dagelijks leven betrokken kan zijn dan een verre vriend. Onderdeel zijn van het dagelijks leven in de wijk is iets wat Singeldingen nastreeft; ze wil de functie vervullen van “een goede buur”. </p>
              <p>Singeldingen is begonnen in 2009 als een initiatief van een kleine groep betrokken bewoners. Zij maakten een plek voor ontspanning en ontmoeting in het Heemraadspark - een groene oase tussen de wijken Middelland en het Nieuwe Westen in Rotterdam.
              In deze dichtbebouwde stadswijken wonen veel verschillende mensen, maar is de ontmoeting niet vanzelfsprekend.</p>
              <p>In het heemraadspark biedt Singeldingen buurtbewoners de kans om elkaar in een ongedwongen sfeer te treffen. Het schaarse groen in deze wijk wordt, door het maken van de zomerkiosk en het bieden van een plek waar men kan aanschuiven, genieten en ontmoeten, veel intensiever benut dan voorheen. Het biedt daarmee een verhoging van de leef kwaliteit en draagt bij aan de sociale en economische staat van de wijk. 
              Singeldingen is een plek voor ontspanning en ontmoeting.</p> 
              <p> Van woensdag tot en met zondag, van mei tot augustus is het park gevuld met een divers programma en ligt de kiosk vol heerlijkheden. Natuurlijk mag je ook lekker komen luieren en is iedereen van harte welkom zijn eigen picknickmand mee te nemen. Zo is het voor iedereen een plek om aan te schuiven, genieten, ontmoeten. Meedoen is onbetaalbaar en hoeft niks te kosten. </p>
              <p>SINGELDINGEN IN ESSENTIE </p>
                <ul>
                <li>	Het zomers hart van de wijken Middelland en Het Nieuwe Westen</li>
                  <li>	Een kiosk met terras in het Heemraadspark, waar je lokaal lekkers kunt kopen, maar ook je eigen lekkers mag eten  </li>
                  <li>	gratis publieke wc</li>
                  <li>	Een gratis voor iedereen toegankelijk cultureel en recreatief programma met voor elk wat wils, maar van hoge kwaliteit</li>
                  <li>	12 weken lang, 5 dagen per week, open</li>
                  <li>	Een kindvriendelijk, schoon en veilig park</li>
                  <li>	Gemaakt door en voor bewoners in Delfshaven</li>
                  <li>	Circa 4.000 uren vrijwilligerswerk </li>
                  <li>	Een parade van ‘kleine verhalen’ over heel verschillende mensen die hun plek vinden bij Singeldingen en hun talenten (opnieuw) benutten</li>
                </ul>


            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )

  
}

export default AboutPage
