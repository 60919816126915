import React from 'react'
import CTAOrg from "./CTAOrg"
const Organization = () => {
  const imagsInfo = [
    {imge:0, name:"Erieen Scheurs", position:"Bestuurslid"},
    {imge:1,name:"Rianne Andeweg", position:"Bestuurslid"},
    {imge:2,name:"Gerdinne Kruik", position:"Bestuurslid"},
    {imge:3,name:"Eva Cheung", position:"Locatie Manager"},
    {imge:4, name:"Carla Delgado-Swiatkowski", position:"Directeur"}]

    const loopInfo = imagsInfo.map((value,index)=>{
      const img = <img className="img-fluid rounded-circle" style={{height:300+"px",width:300+"px"}} src={`template/img/thumbnail_image${index}.jpg` } alt={`thumbnail_image${index}`}/>
      const title = <><h2 className="display-4" style={{color:"white"}}>{value.name}</h2>
                      <p style={{color:"white"}}>{value.position}</p></>                      
      return (
        <div className="row align-items-center" key={index}>
         <div className="col-lg-6 order-lg-1">
                <div className="p-5">
                  {index % 2 === 0? img: title}
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="p-5">
                  {index % 2 !== 0 ?img: title}
                </div>
              </div>
        </div>
      )
    })

    return (
        <>
          <CTAOrg />
          <section>
            <div className="container">
              {loopInfo}
            </div>
          </section>
       </>
    )
}

export default Organization
