import React from 'react'
import Address from '../StorePage/components/Address';
const CTA = () => {
  return (
    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
              <h2 className="section-heading mb-4">
                {/* <span className="section-heading-upper">Our Promise</span> */}
                <span className="section-heading-lower">Organisatie Van Singeldingen</span>
              </h2>
              <p className="mb-0">
              Bestuur bestaat uit een van de oprichters Eireen Scheurs,
                 Rianne Anderweg, Gerdien Kruik. Dagellijkse leiding/ 
                 coordinatie ligt in de handen van  directeur Carla Swiatkowski.
                  Locatiemanager Eva Cheung </p>
                  <p>Singeldingen heeft een ANBI status!</p>
                  <Address />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTA
