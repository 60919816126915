import React from 'react'
import OpenTime from './components/OpenTime';
import Contact from './components/Contact';
import Google from './components/Google';


const Store = () => {
  return (
    <div>
      <Contact/>
        <OpenTime />
        <Google />
        
    </div>
  )
}

export default Store
