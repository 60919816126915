const Time = [
    {day:"Zondag", time:"12:00 tot 18:00"},
    {day:"Maandag", time:"Gesloten"},
    {day:"Disdag", time:"Gesloten"},
    {day:"Woensdag", time:"10:00 tot 18:00"},
    {day:"Donderdag", time:"12:00 tot 18:00"},
    {day:"Vrijdag", time:"12:00 tot 20:00"},
    {day:"Zaterdag", time:"10:00 tot 18:00"},
]

export default Time